import { useCallback, useEffect, useMemo, useRef } from "react";
import {
  LocalUser,
  useJoin,
  useLocalMicrophoneTrack,
  useLocalCameraTrack,
  usePublish,
  useRemoteUsers,
  useRTCClient,
  useClientEvent,
} from "agora-rtc-react";
import { useDeepDiveContext } from "../DeepDiveProvider";
import {
  CALL_END_REASON,
  CALL_PROGRESS,
  INTERACTIONS_BETWEEN_PARTICIPANTS_TYPE,
  RemoteStreams,
  RemoteUserMap,
  SESSION_MODE,
  STREAM_TYPE,
  TOAST_TIME,
  USER_TYPE,
} from "../constants";
import { Joinee, SessionInfo, Uids } from "../deepdiveSlice";
import UserTrackInfo from "./UserTrackInfo";
import SimpleBar from "simplebar-react";
import { App, Layout } from "antd";
import { debounce } from "lodash";
import NoParticipants from "./callLayouts/NoParticipants";
import FocusGroup from "./callLayouts/FocusGroup";
import IDT from "./callLayouts/IDT";
import CallControlButtons from "./CallControlButtons";
import RemoteUserStream from "./callLayouts/RemoteUserStream";
import { api } from "../api";

const { Sider, Content } = Layout;

const Call = () => {
  const {
    appId,
    channel,
    tokens,
    callProgress,
    micOn,
    cameraOn,
    joinee,
    getMembers,
    sessionInfo,
    members,
    updateDeepDiveState,
    isRecorder,
    t,
    quitCall,
    calling,
    setCalling,
    lang,
  } = useDeepDiveContext();
  const {
    uids = {},
    joinee: { role },
  } = (joinee as Joinee) || {};
  const { cameraAudioUid, screenUid } = (uids as Uids) || {};
  const { cameraAudio: cameraAudioToken = "" } = tokens || {};
  const { sessionMode, id: sessionId, interactionBetweenParticipants } = (sessionInfo as SessionInfo) || {};

  const { message } = App.useApp();
  const beenOfflineRef = useRef(false);

  useEffect(() => {
    const updateOnlineStatus = () => {
      if (!navigator.onLine) {
        message.info(t("OFFLINE"), TOAST_TIME);
        updateDeepDiveState({ micOn: false, cameraOn: false, screenOn: false });
        setCalling(false);
        beenOfflineRef.current = true;
      }

      if (navigator.onLine && beenOfflineRef.current) {
        message.success(t("ONLINE"), TOAST_TIME);
        setCalling(true);
      }
    };
    updateOnlineStatus();

    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, [message, setCalling, t, updateDeepDiveState]);

  const containerStyle = {
    height: !isRecorder ? "calc(100vh - 57px)" : "100vh",
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUserJoined = useCallback(
    debounce((user) => {
      getMembers();
    }, 300),
    []
  );

  // Fetch all members from server when a new user joins
  const client = useRTCClient();
  useClientEvent(client, "user-joined", (user) => {
    debouncedUserJoined(user);
  });

  useJoin(
    { appid: appId, channel: channel, token: cameraAudioToken || null, uid: cameraAudioUid },
    callProgress === CALL_PROGRESS.IN_CALL_SCREEN && calling
  );

  // local user
  const { localMicrophoneTrack, error: micError } = useLocalMicrophoneTrack(micOn);
  const { localCameraTrack, error: cameraError } = useLocalCameraTrack(cameraOn);
  usePublish([localMicrophoneTrack, localCameraTrack]);

  // error while mic sharing
  useEffect(() => {
    if (micOn && micError) {
      message.error(t("FAILED_MIC"), TOAST_TIME);
      updateDeepDiveState({ micOn: false });
    }
  }, [micOn, micError, updateDeepDiveState, message, t]);

  // error while camera sharing
  useEffect(() => {
    if (cameraOn && cameraError) {
      message.error(t("FAILED_CAMERA"), TOAST_TIME);
      updateDeepDiveState({ cameraOn: false });
    }
  }, [cameraOn, cameraError, updateDeepDiveState, message, t]);

  // remote users
  let remoteUsers = useRemoteUsers();

  // in recorder - stop recording when all remote users leave
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const timeout = 15000;
  useEffect(() => {
    if (sessionId && role === USER_TYPE.RECORDER) {
      if (remoteUsers.length === 0) {
        // Start a timer if one isn't already running
        if (!timerRef.current) {
          timerRef.current = setTimeout(() => {
            api.stopRecording(lang, sessionId);
            quitCall(CALL_END_REASON.LEAVE, false);
            timerRef.current = null; // Reset the timer reference
          }, timeout);
        }
      } else {
        // If remote users are present, clear any existing timer
        if (timerRef.current) {
          clearTimeout(timerRef.current);
          timerRef.current = null;
        }
      }
    }

    // Cleanup function to clear the timer when the component unmounts
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    };
  }, [lang, quitCall, remoteUsers.length, role, sessionId]);

  // Group remote users by role and stream types
  const remoteStreams = useMemo(() => {
    const remoteUserIds = remoteUsers?.reduce<number[]>((acc, user) => {
      if (!acc.includes(Number(user.uid))) {
        acc.push(Number(user.uid));
      }
      return acc;
    }, []);

    const remoteUserMaps: RemoteUserMap = {};

    remoteUserIds?.forEach((uid) => {
      const remoteUser = remoteUsers.find((user) => user.uid === uid);
      if (members && members[uid] && remoteUser) {
        const member = members[uid];
        const memberRole = member.role as USER_TYPE;
        const streamType = member.stream_type as STREAM_TYPE;
        const screenWithNoTrack = streamType === STREAM_TYPE.SCREEN && !remoteUser.hasVideo;
        if (screenWithNoTrack) return; // Skip screen sharing without video track
        if (
          interactionBetweenParticipants === INTERACTIONS_BETWEEN_PARTICIPANTS_TYPE.OFF &&
          role === USER_TYPE.PARTICIPANT &&
          memberRole === USER_TYPE.PARTICIPANT &&
          streamType === STREAM_TYPE.SCREEN &&
          remoteUser.uid !== screenUid
        )
          return; // Skip other participant's screen streams if interaction between participants is off
        if (!remoteUserMaps[memberRole]) {
          remoteUserMaps[memberRole] = {};
        }
        if (remoteUserMaps[memberRole] && !remoteUserMaps[memberRole]![streamType]) {
          remoteUserMaps[memberRole]![streamType] = [];
        }
        remoteUserMaps[memberRole]![streamType]!.push(remoteUser);
      }
    });

    const remoteStreams: RemoteStreams = {
      moderatorScreens: [],
      participantScreens: [],
      moderatorCamerasMics: [],
      participantCamerasMics: [],
      translatorCamerasMics: [],
      observerCamerasMics: [],
    };

    Object.keys(remoteUserMaps).forEach((role) => {
      const userRole = role as USER_TYPE;

      if (remoteUserMaps[userRole]?.[STREAM_TYPE.SCREEN]) {
        const screens = remoteUserMaps[userRole]![STREAM_TYPE.SCREEN] || [];

        if (userRole === USER_TYPE.MODERATOR) {
          remoteStreams.moderatorScreens = screens;
        } else if (userRole === USER_TYPE.TESTER) {
          remoteStreams.participantScreens = screens;
        }
      }

      if (remoteUserMaps[userRole]?.[STREAM_TYPE.CAMERA_MIC]) {
        const camerasMics = remoteUserMaps[userRole]![STREAM_TYPE.CAMERA_MIC] || [];

        if (userRole === USER_TYPE.MODERATOR) {
          remoteStreams.moderatorCamerasMics = camerasMics;
        } else if (userRole === USER_TYPE.TESTER) {
          remoteStreams.participantCamerasMics = camerasMics;
        } else if (userRole === USER_TYPE.TRANSLATOR) {
          remoteStreams.translatorCamerasMics = camerasMics;
        } else if (userRole === USER_TYPE.OBSERVER) {
          remoteStreams.observerCamerasMics = camerasMics;
        }
      }
    });

    return remoteStreams;
  }, [interactionBetweenParticipants, members, remoteUsers, role, screenUid]);

  const remoteParticipantAvailable = useMemo(() => {
    return !(
      !remoteStreams ||
      (remoteStreams && !remoteStreams.participantCamerasMics.length && !remoteStreams.participantScreens.length)
    );
  }, [remoteStreams]);

  const sideRemoteStreams = useMemo(() => {
    const sideRemoteStreams = [
      ...(sessionMode !== SESSION_MODE.FOCUS_GROUP ? remoteStreams.participantCamerasMics : []),
      ...(!remoteParticipantAvailable && role !== USER_TYPE.TESTER ? remoteStreams.moderatorScreens : []),
      ...remoteStreams.moderatorCamerasMics,
      ...remoteStreams.translatorCamerasMics,
    ];
    return sideRemoteStreams;
  }, [remoteParticipantAvailable, remoteStreams, role, sessionMode]);

  return (
    <>
      <CallControlButtons remoteStreams={remoteStreams} />
      <div style={containerStyle}>
        <>
          <Layout className='h-100'>
            <Content className='p-2 bg-E3E8EF' style={{ width: "calc(100vw - 230px - 2rem)" }}>
              {!remoteParticipantAvailable && role !== USER_TYPE.TESTER && <NoParticipants />}
              {(remoteParticipantAvailable || (!remoteParticipantAvailable && role === USER_TYPE.TESTER)) &&
                sessionMode !== SESSION_MODE.FOCUS_GROUP && <IDT remoteStreams={remoteStreams} />}
              {(remoteParticipantAvailable || (!remoteParticipantAvailable && role === USER_TYPE.TESTER)) &&
                sessionMode === SESSION_MODE.FOCUS_GROUP && (
                  <FocusGroup
                    localMicrophoneTrack={localMicrophoneTrack}
                    localCameraTrack={localCameraTrack}
                    remoteStreams={remoteStreams}
                  />
                )}
            </Content>
            <Sider width={230} className={`h-100 ${sessionMode === SESSION_MODE.FOCUS_GROUP ? "bg-EEF2F6" : "bg-E3E8EF"}`}>
              <SimpleBar className='h-100'>
                <div className='d-flex gap-2 p-2 flex-wrap flex-column'>
                  {sideRemoteStreams.map((user) => {
                    return <RemoteUserStream isSideBar={true} user={user} key={user.uid} />;
                  })}

                  {!isRecorder && !(role === USER_TYPE.TESTER && sessionMode === SESSION_MODE.FOCUS_GROUP) && (
                    <div className='user'>
                      <LocalUser
                        className='localUser'
                        audioTrack={localMicrophoneTrack}
                        cameraOn={cameraOn}
                        micOn={micOn}
                        videoTrack={localCameraTrack}
                        playAudio={false}
                      >
                        <UserTrackInfo isLocal={true} />
                      </LocalUser>
                    </div>
                  )}
                </div>
              </SimpleBar>
            </Sider>
          </Layout>
        </>
      </div>
    </>
  );
};

export default Call;
