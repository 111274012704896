import { Button, Typography } from "antd";
import CustomIcon from "../CustomIcon";
import { useDeepDiveContext } from "../../DeepDiveProvider";
import { CALL_PROGRESS } from "../../constants";
import languages from "../../shared/languages";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const { Title } = Typography;

const LanguageSelectionScreen = () => {
  const { joiningKey } = useParams();
  const rolePrefix = joiningKey?.[0];
  const isRecorder = rolePrefix === "r";

  const { updateDeepDiveState, lang } = useDeepDiveContext();

  const languagesDropdown = Object.fromEntries(languages.map(({ key, text }) => [key, text]));

  useEffect(() => {
    // For recorder to bypass language selection screen
    if (isRecorder) {
      updateDeepDiveState({
        lang: "en",
        callProgress: CALL_PROGRESS.WAITING_SCREEN,
      });
    }
  }, [isRecorder, updateDeepDiveState]);

  return (
    <div className='h-100vh w-50 m-auto d-flex flex-column align-items-center flex-center gap-2'>
      <CustomIcon name='language_globe' />
      <Title level={4} className='m-0 text-black pb-1 font-size-28px'>
        Choose a language
      </Title>
      <div className='h-50vh w-70 overflow-auto pr-2 lang-sel'>
        {Object.keys(languagesDropdown).map((key) => (
          <Button
            key={key}
            size='large'
            className={`w-100 d-flex flex-start mb-1 font-size-16px ${lang !== key ? "secondary-btn" : ""}`}
            onClick={() => {
              updateDeepDiveState({
                lang: key,
                callProgress: CALL_PROGRESS.WAITING_SCREEN,
              });
            }}
          >
            {languagesDropdown[key]}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default LanguageSelectionScreen;
