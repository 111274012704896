import { useState } from "react";
import { useDeepDiveContext } from "../../DeepDiveProvider";
import { SIGNAL_TYPE, STREAM_TYPE } from "../../constants";
import CustomIcon from "../CustomIcon";
import { Button, Input, Spin, Typography } from "antd";
import { Joinee, JoineeInfo, Member, SessionInfo, Uids } from "../../deepdiveSlice";
import { api } from "../../api";
import { useRTMContext } from "../../RTMProvider";

const { Title, Text } = Typography;

const MIN_NAME_LENGTH = 2;
const MAX_NAME_LENGTH = 30;

const GetUserInfoScreen = () => {
  const { updateDeepDiveState, joinee, joinCall, sessionInfo, channel, isRecorder, t, lang } = useDeepDiveContext();
  const { sendSignal } = useRTMContext();
  const {
    uids = {},
    joinee: { id, role, is_chief_moderator, is_allowed },
  } = (joinee as Joinee) || {};
  const { cameraAudioUid } = (uids as Uids) || {};
  const { id: sessionId, sessionMode } = (sessionInfo as SessionInfo) || {};

  const [userName, setUserName] = useState("");
  const [waitingForAllow, setWaitingForAllow] = useState(false);

  const joinSession = (name: string) => {
    const userName = name?.trim();

    if (is_allowed) {
      // If user is already allowed
      joinCall(userName);
    } else {
      // If user is not allowed, wait for moderator to allow
      setWaitingForAllow(true);
      api
        .updateJoinee(
          lang,
          sessionId,
          sessionMode,
          channel,
          Number(id),
          userName,
          role,
          Boolean(is_chief_moderator),
          "participantRequestedToJoin"
        )
        .then((response) => {
          const res = response?.data?.joinee;
          const joineeInfo: JoineeInfo = res?.joinee as JoineeInfo;
          const uids: Uids = res?.uids as Uids;
          updateDeepDiveState({ joinee: { joinee: joineeInfo, uids } });
          const member: Member = {
            is_chief_moderator: joineeInfo.is_chief_moderator,
            is_mobile: joineeInfo.is_mobile,
            name: joineeInfo.name,
            role: joineeInfo.role,
            stream_type: STREAM_TYPE.CAMERA_MIC,
            is_allowed: joineeInfo.is_allowed,
          };
          sendSignal(SIGNAL_TYPE.PARTICIPANT_REQUSTED_TO_JOIN, cameraAudioUid, id, member);
        })
        .catch(() => {});
    }
  };

  return (
    <>
      {!isRecorder && (
        <>
          <CustomIcon name='deepdive' />
          <div className='d-flex flex-column flex-center align-items-center mt-4'>
            <CustomIcon name='higirl' />
            <Title className='font-size-30px'>
              {t("HELLO")} <span className='user-role'>{t(role?.toUpperCase())}</span>
            </Title>
            {!waitingForAllow && (
              <>
                <Text className='font-size-17px color-426BCD'>{t("ENTER_YOUR_NAME")}</Text>
                <Input
                  className='mt-4 w-25 font-size-17px border-none border-radius-0 pb-05 border-bottom-506fff color-506FFF outline-none box-shadow-unset'
                  autoFocus
                  placeholder={t("ENTER_YOUR_NAME_PLACEHOLDER")}
                  minLength={MIN_NAME_LENGTH}
                  maxLength={MAX_NAME_LENGTH}
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && userName?.trim()?.length >= MIN_NAME_LENGTH) {
                      joinSession(userName);
                    }
                  }}
                />
                <Button
                  className='mt-4'
                  size='large'
                  type='primary'
                  disabled={!userName || userName?.trim()?.length < MIN_NAME_LENGTH}
                  onClick={() => joinSession(userName)}
                >
                  {t("JOIN_SESSION")}
                </Button>
              </>
            )}
            {waitingForAllow && (
              <>
                <Text className='font-size-17px color-426BCD'>{t("WAITING_FOR_MODERATOR")}</Text>
                <Spin className='mt-4' size='large' />
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default GetUserInfoScreen;
