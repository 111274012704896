import { useDeepDiveContext } from "../DeepDiveProvider";
import { CALL_PROGRESS } from "../constants";
import { useEffect, useMemo, useRef } from "react";
import WaitingScreen from "./entryLayouts/WaitingScreen";
import ErrorScreen from "./entryLayouts/ErrorScreen";
import GetUserInfoScreen from "./entryLayouts/GetUserInfoScreen";
import ThankYouScreen from "./entryLayouts/ThankYouScreen";
import { useParams } from "react-router-dom";
import CallScreen from "./entryLayouts/CallScreen";
import LanguageSelectionScreen from "./entryLayouts/LanguageSelectionScreen";

export default function Entry() {
  const { callProgress, verifySession } = useDeepDiveContext();

  const { joiningKey } = useParams();
  const rolePrefix = joiningKey?.[0];
  const isRecorder = rolePrefix === "r";
  const hasFetched = useRef(false);

  const toVerifySession = isRecorder && joiningKey && callProgress === CALL_PROGRESS.WAITING_SCREEN;

  useEffect(() => {
    if (toVerifySession) {
      if (hasFetched.current) return;
      hasFetched.current = true;

      verifySession(joiningKey || "", true);
    }
  }, [callProgress, isRecorder, joiningKey, toVerifySession, verifySession]);

  const content = useMemo(() => {
    if (toVerifySession) return null;

    switch (callProgress) {
      case CALL_PROGRESS.LANGUAGE_SELECTION_SCREEN:
        return <LanguageSelectionScreen />;
      case CALL_PROGRESS.WAITING_SCREEN:
        return <WaitingScreen />;
      case CALL_PROGRESS.ERROR_SCREEN:
        return <ErrorScreen />;
      case CALL_PROGRESS.GET_USER_INFO_SCREEN:
        return <GetUserInfoScreen />;
      case CALL_PROGRESS.IN_CALL_SCREEN:
        return <CallScreen />;
      case CALL_PROGRESS.THANK_YOU_SCREEN:
        return <ThankYouScreen />;
      default:
        return null;
    }
  }, [callProgress, toVerifySession]);

  return <div className='entry'>{content}</div>;
}
