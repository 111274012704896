import { Button, Input, Tabs, Tooltip, Typography } from "antd";
import { useDeepDiveContext } from "../../DeepDiveProvider";
import CustomIcon from "../CustomIcon";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  CHAT_MESSAGE_TYPE,
  CHAT_ROOM,
  createHyperlinks,
  DEEPDIVE_ROLE_ICONS,
  formatTimestampToTime,
  SIGNAL_TYPE,
  USER_TYPE,
} from "../../constants";
import { SendOutlined } from "@ant-design/icons";
import { api } from "../../api";
import { ChatInterface, ChatMessage, Feature, Joinee, SessionInfo, Uids } from "../../deepdiveSlice";
import SimpleBar from "simplebar-react";
import UserInfo from "./UserInfo";
import { useRTMContext } from "../../RTMProvider";

const { Title, Text } = Typography;

const ChatInput = ({ sendMessage, t }: { sendMessage: (msg: string) => void; t: any }) => {
  const [inputMsg, setInputMsg] = useState("");
  const textAreaRef = useRef<any>(null);

  const handleSendMessage = () => {
    if (inputMsg?.trim().length) {
      sendMessage(inputMsg);
      setInputMsg("");
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
    const pasteText = e.clipboardData.getData("text");
    const currentText = inputMsg;
    const newText = currentText + pasteText;

    if (newText.length > 1000) {
      e.preventDefault();
      const trimmedText = newText.substring(0, 1000);
      setInputMsg(trimmedText);
    }

    // Trigger manual resizing after paste
    setTimeout(() => {
      if (textAreaRef.current?.resizableTextArea?.textArea) {
        // Focus and blur the actual textarea to force a resize
        const actualTextArea = textAreaRef.current.resizableTextArea.textArea;
        actualTextArea.blur();
        actualTextArea.focus();
      }
    }, 0);
  };

  return (
    <div className='chat-input-container d-flex align-items-center gap-1'>
      <Input.TextArea
        ref={textAreaRef}
        className='chat-input'
        placeholder={t("ENTER_YOUR_CHAT_PLACEHOLDER")}
        value={inputMsg}
        onChange={(e) => setInputMsg(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage();
          }
        }}
        size='large'
        autoSize={{ minRows: 1, maxRows: 5 }}
        style={{ flex: 1 }}
        maxLength={1000}
        onPaste={handlePaste}
      />
      <Tooltip title={t("SEND_MESSAGE")} placement='left' rootClassName='zi-tooltip'>
        <Button className='send-button' type='text' disabled={inputMsg.trim().length === 0} onClick={handleSendMessage}>
          <SendOutlined />
        </Button>
      </Tooltip>
    </div>
  );
};

const Chat = () => {
  const { updateDeepDiveState, sessionInfo, joinee, chats, members, setShowFeature, setNewChatReceived, t, lang } =
    useDeepDiveContext();
  const { sendSignal } = useRTMContext();
  const { id: sessionId } = sessionInfo as SessionInfo;
  const {
    uids = {},
    joinee: { role, name, id },
  } = joinee as Joinee;
  const { cameraAudioUid } = uids as Uids;
  const { internal: internalChats, participant: participantChats } = chats as ChatInterface;

  const isParticipantTabAllowed = role === USER_TYPE.TESTER || role === USER_TYPE.MODERATOR;
  const isInternalTabAllowed = role !== USER_TYPE.TESTER;

  const [activeKey, setActiveKey] = useState<CHAT_ROOM>(isParticipantTabAllowed ? CHAT_ROOM.PARTICIPANT : CHAT_ROOM.INTERNAL);

  const member = useMemo(() => members && members[cameraAudioUid], [members, cameraAudioUid]);
  const memberRef = useRef(member);

  useEffect(() => {
    memberRef.current = member;
  }, [member]);

  const MsgView = ({ chat }: { chat: ChatMessage }) => {
    const icon = DEEPDIVE_ROLE_ICONS[chat.userRole.toLowerCase() as keyof typeof DEEPDIVE_ROLE_ICONS];

    return (
      <div className='d-flex flex-center flex-column gap-1'>
        <div className='d-flex align-items-center space-between'>
          <div className='d-flex align-items-center gap-1'>
            <UserInfo userName={chat.userName} isSelf={id === chat.connectionID} isSmall={true} />
            <CustomIcon name={icon} width={16} height={16} />
          </div>
          <Text type='secondary' className='font-size-12px'>
            {formatTimestampToTime(chat.timestamp)}
          </Text>
        </div>
        <div className='bg-F8FAFC p-8-12 msg-view word-break' dangerouslySetInnerHTML={{ __html: createHyperlinks(chat.comment) }} />
      </div>
    );
  };

  const MessageWindow = ({ type }: { type: CHAT_ROOM }) => {
    const chats = type === CHAT_ROOM.PARTICIPANT ? participantChats : internalChats;
    const simpleBarRef = useRef<HTMLElement | null>(null);

    useEffect(() => {
      if (simpleBarRef.current) {
        const scrollElement = simpleBarRef.current;
        scrollElement.scrollTop = scrollElement.scrollHeight;
      }
    }, [chats]);

    return (
      <div className={`h-100 ${chats?.length === 0 ? "bg-F8FAFC " : "bg-white"}`}>
        {chats?.length === 0 && (
          <div className='d-flex align-items-center flex-center flex-column gap-1 m-auto h-100'>
            <CustomIcon name='message-smile-square' />
            <Text strong>{t("START_CHAT")}</Text>
          </div>
        )}
        {(chats?.length ?? 0) > 0 && (
          <SimpleBar className='w-100 h-100' scrollableNodeProps={{ ref: simpleBarRef }}>
            <div className='d-flex flex-center flex-column gap-2 p-2'>
              {chats?.map((chat, index) => (
                <MsgView chat={chat} key={index} />
              ))}
            </div>
          </SimpleBar>
        )}
      </div>
    );
  };

  const tabItems = [];
  if (isParticipantTabAllowed) {
    tabItems.push({
      key: CHAT_ROOM.PARTICIPANT,
      label: t("CHAT_ROOM_PARTICIPANT"),
      children: <MessageWindow type={CHAT_ROOM.PARTICIPANT} />,
    });
  }
  if (isInternalTabAllowed) {
    tabItems.push({
      key: CHAT_ROOM.INTERNAL,
      label: t("CHAT_ROOM_INTERNAL"),
      children: <MessageWindow type={CHAT_ROOM.INTERNAL} />,
    });
  }

  const sendMessage = useCallback(
    (msg: string) => {
      const chat: ChatMessage = {
        timestamp: Math.floor(Date.now() / 1000),
        connectionID: id,
        userRole: role,
        userId: role,
        userName: name,
        messageType: activeKey === CHAT_ROOM.PARTICIPANT ? CHAT_MESSAGE_TYPE.TESTER_MODERATOR : CHAT_MESSAGE_TYPE.TEAM,
        comment: msg?.trim(),
      };
      api
        .saveChat(lang, sessionId, chat)
        .then((data) => {
          const newChat = data?.data?.chat as ChatMessage;
          if (newChat) {
            updateDeepDiveState({
              chats: {
                ...chats,
                [activeKey]: [...(chats?.[activeKey] ?? []), newChat],
              },
            });
            sendSignal(SIGNAL_TYPE.CHAT, cameraAudioUid, id, memberRef?.current, undefined, newChat);
          }
        })
        .catch(() => {});
    },
    [activeKey, cameraAudioUid, chats, id, lang, name, role, sendSignal, sessionId, updateDeepDiveState]
  );

  return (
    <div
      style={{ width: 400, height: 400 }}
      className='bg-white border-radius-12px bs position-fixed bottom-2 right-2 zi-10k overflow-hidden'
    >
      <div className='d-flex gap-1 p-2'>
        <Button
          className='m-0'
          size='small'
          type='text'
          onClick={() => {
            setShowFeature((prev) => (prev !== Feature.CHAT ? Feature.CHAT : undefined));
            setNewChatReceived(false);
          }}
        >
          <CustomIcon name='x-close' />
        </Button>
        <Title className='m-0' level={4}>
          {t("CHAT_ROOM")}
        </Title>
      </div>

      <Tabs
        style={{ height: `calc(100% - 60px - 44px)` }}
        activeKey={activeKey}
        onChange={(key) => setActiveKey(key as CHAT_ROOM)}
        items={tabItems}
        centered
        rootClassName='chatbox-tabs'
      />

      <ChatInput sendMessage={sendMessage} t={t} />
    </div>
  );
};

export default Chat;
