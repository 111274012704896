import { Typography } from "antd";
import { useDeepDiveContext } from "../../DeepDiveProvider";
import { SessionInfo } from "../../deepdiveSlice";
import CustomIcon from "../CustomIcon";
import CopyText from "./CopyText";
import { capitalizeFirstLetter, DEEPDIVE_ROLE_ICONS } from "../../constants";

const { Paragraph } = Typography;

const InviteModal = () => {
  const { sessionInfo, t } = useDeepDiveContext();
  const { links } = sessionInfo as SessionInfo;

  return (
    <div style={{ width: 400, right: "-12rem" }} className='bg-white border-radius-12px bs position-absolute top-8 zi-10k p-2'>
      <Paragraph className='font-weight-500 color-121926 '>{t("COPY_SHARE_LINK")}</Paragraph>

      {links &&
        Object.keys(links)?.map((key: string, i) => {
          const name = capitalizeFirstLetter(key);
          const link = links[key as keyof typeof links];
          const icon = DEEPDIVE_ROLE_ICONS[key as keyof typeof DEEPDIVE_ROLE_ICONS];
          return (
            <div key={name} className={Object.keys(links)?.length - 1 !== i ? "mb-2" : "m-0"}>
              <div className='d-flex align-items-center space-between'>
                <Paragraph className='d-flex align-items-center gap-1 font-weight-500 mb-1'>
                  <CustomIcon name={icon} height={22} width={22} />
                  {t(`${name.toUpperCase()}_LINK`)}
                </Paragraph>
              </div>

              <CopyText link={link} />
            </div>
          );
        })}
    </div>
  );
};

export default InviteModal;
