import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import languages from "./shared/languages";

// the resources
const resources = languages.reduce((obj, { key, translation }) => ({ ...obj, [key]: { translation } }), {});

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  supportedLngs: languages.map((lang) => lang.key),
  keySeparator: false,
  interpolation: { escapeValue: false },
});

export default i18n;
