// translation files
import ar from "./locales/ar.json";
import bn from "./locales/bn.json";
import de from "./locales/de.json";
import en from "./locales/en.json";
import es from "./locales/es.json";
import fr from "./locales/fr.json";
import hi from "./locales/hi.json";
import id from "./locales/id.json";
import it from "./locales/it.json";
import ja from "./locales/ja.json";
import ko from "./locales/ko.json";
import ptBr from "./locales/pt-br.json";
import th from "./locales/th.json";
import vi from "./locales/vi.json";
import zhHans from "./locales/zh-Hans.json";
import zhHant from "./locales/zh-Hant.json";
import tl from "./locales/tl.json";

// the translations
const languages = [
  { key: "en", text: "English (US)", translation: en },
  { key: "ar", text: "Arabic: عربي", translation: ar },
  { key: "bn", text: "Bengali: বাংলা", translation: bn },
  { key: "zh-Hans", text: "Chinese (Simplified): 简体中文", translation: zhHans },
  { key: "zh-Hant", text: "Chinese (Traditional): 繁體中文", translation: zhHant },
  { key: "fr", text: "French: Français", translation: fr },
  { key: "de", text: "German: Deutsch", translation: de },
  { key: "hi", text: "Hindi: हिन्दी", translation: hi },
  { key: "id", text: "Indonesian: Bahasa Indonesia", translation: id },
  { key: "it", text: "Italian: Italiano", translation: it },
  { key: "ja", text: "Japanese: 日本語", translation: ja },
  { key: "ko", text: "Korean: 한국어", translation: ko },
  { key: "pt-BR", text: "Portugese (Brazil): Português brasileiro", translation: ptBr },
  { key: "es", text: "Spanish: Español", translation: es },
  { key: "tl", text: "Tagalog: Tagalog", translation: tl },
  { key: "th", text: "Thai: ภาษาไทย", translation: th },
  { key: "vi", text: "Vietnamese: Tiếng Việt", translation: vi },
];

export default languages;
