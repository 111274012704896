import PropTypes from "prop-types";
import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DeepDiveState, Feature, Joinee, Member, SessionInfo, updateState } from "./deepdiveSlice";
import { RootState } from "./store";
import { api } from "./api";
import {
  AUDIO_CHANNELS_TYPE,
  CALL_END_REASON,
  CALL_PROGRESS,
  INTERACTIONS_BETWEEN_PARTICIPANTS_TYPE,
  STREAM_TYPE,
  USER_TYPE,
  WaitingParticipantInterface,
} from "./constants";
import { UID } from "agora-rtc-react";
import { useTranslation } from "react-i18next";
import rtlDetect from "rtl-detect";

interface DeepDiveContextType extends DeepDiveState {
  updateDeepDiveState: (newState: Partial<DeepDiveState>) => void;
  getMembers: () => void;
  quitCall: (key: string, toRejoin?: boolean, endReason?: string, showRejoinBtn?: boolean) => void;
  waitingParticipants: WaitingParticipantInterface[];
  setWaitingParticipants: React.Dispatch<React.SetStateAction<WaitingParticipantInterface[]>>;
  verifySession: (joiningKey: string, isRecorder?: boolean, autoJoin?: boolean) => void;
  joinCall: (userName: string) => void;
  isRecorder: boolean;
  isMac: boolean;
  showFeature?: Feature;
  setShowFeature: React.Dispatch<React.SetStateAction<Feature | undefined>>;
  selectedAudioChannel: AUDIO_CHANNELS_TYPE;
  setSelectedAudioChannel: React.Dispatch<React.SetStateAction<AUDIO_CHANNELS_TYPE>>;
  selectedAudioChannelUids: UID[];
  setSelectedAudioChannelUids: React.Dispatch<React.SetStateAction<UID[]>>;
  newChatReceived: boolean;
  setNewChatReceived: React.Dispatch<React.SetStateAction<boolean>>;
  t: any;
  calling: boolean;
  setCalling: React.Dispatch<React.SetStateAction<boolean>>;
  limitInteractionDesc: (key: INTERACTIONS_BETWEEN_PARTICIPANTS_TYPE) => { msg: string; descriptionMsg: string };
}

// DeepDiveContext to keep the latest state
const DeepDiveContext = createContext<DeepDiveContextType | undefined>(undefined);

// useDeepDiveContext to share this components state everywhere this component is imported
export const useDeepDiveContext = (): DeepDiveContextType => {
  const context = useContext(DeepDiveContext);

  if (!context) throw new Error("useDeepDiveContext context must be use inside DeepDiveProvider");

  return context;
};

interface DeepDiveProviderProps {
  children: ReactNode;
}

const DeepDiveProvider: React.FC<DeepDiveProviderProps> = ({ children }) => {
  const { t, i18n } = useTranslation();

  const deepDiveState = useSelector((state: RootState) => state.deepdive);
  const dispatch = useDispatch();

  const { sessionInfo, joinee, callProgress, channel, browserUuid, autoJoin, lang } = deepDiveState;

  const { joinee: joineeInfo } = joinee ?? {};

  const [waitingParticipants, setWaitingParticipants] = useState<WaitingParticipantInterface[]>([]);

  const hasFetched = useRef(false);

  const [isRecorder, setIsRecorder] = useState(false);
  const [showFeature, setShowFeature] = useState<Feature | undefined>(undefined);
  const [selectedAudioChannel, setSelectedAudioChannel] = useState(AUDIO_CHANNELS_TYPE.main);
  const [selectedAudioChannelUids, setSelectedAudioChannelUids] = useState<UID[]>([]);
  const [newChatReceived, setNewChatReceived] = useState(false);
  const [calling, setCalling] = useState(true);

  const isMac = useMemo(() => navigator.platform.toUpperCase().includes("MAC"), []);

  // Change language
  useEffect(() => {
    i18n.changeLanguage(lang);
    const isRtl = rtlDetect.isRtlLang(lang);
    document.documentElement.dir = isRtl ? "rtl" : "ltr";
  }, [i18n, lang]);

  // Update state
  const updateDeepDiveState = useCallback(
    (newState: Partial<DeepDiveState>) => {
      dispatch(updateState(newState));
    },
    [dispatch]
  );

  // Get members
  const getMembers = useCallback(() => {
    if (sessionInfo) {
      const sessionId = sessionInfo?.id;

      api
        .getJoinees(lang, Number(sessionId))
        .then((response) => {
          const members = response?.data?.joinees?.data;
          updateDeepDiveState({ members });
          if (joineeInfo?.role === USER_TYPE.MODERATOR) {
            // Moderator: Get participants waiting for approval
            let participantsWaiting: WaitingParticipantInterface[] = [];
            Object.keys(members).forEach((key) => {
              const member: Member = members[Number(key)];
              if (!member?.is_allowed && member?.stream_type === STREAM_TYPE.CAMERA_MIC && member?.name) {
                participantsWaiting.push({ uid: Number(key), member });
              }
            });
            setWaitingParticipants(participantsWaiting);
          }
        })
        .catch(() => {});
    }
  }, [joineeInfo?.role, lang, sessionInfo, updateDeepDiveState]);

  useEffect(() => {
    if (joineeInfo?.role && callProgress === CALL_PROGRESS.IN_CALL_SCREEN) getMembers();
  }, [callProgress, getMembers, joineeInfo?.role]);

  // Join call
  const joinCall = useCallback(
    (userName: string) => {
      if (sessionInfo && joinee) {
        const { id: sessionId, sessionMode } = sessionInfo as SessionInfo;
        const {
          joinee: { id, role, is_chief_moderator },
        } = joinee as Joinee;

        api
          .updateJoinee(
            lang,
            sessionId,
            sessionMode,
            channel,
            Number(id),
            userName,
            role,
            Boolean(is_chief_moderator),
            "sessionJoined"
          )
          .then((response) => {
            const { joinee } = response?.data;
            updateDeepDiveState({ callProgress: CALL_PROGRESS.IN_CALL_SCREEN, joinee });
            api.sendJoinStatus(lang, Number(sessionId), role, Boolean(is_chief_moderator), userName, Number(id), true);
          })
          .catch((e) => {
            updateDeepDiveState({ callProgress: CALL_PROGRESS.ERROR_SCREEN, error: e?.response?.data?.message });
          });
      }
    },
    [channel, joinee, lang, sessionInfo, updateDeepDiveState]
  );

  // Verfiy session
  const verifySession = useCallback(
    (joiningKey: string, isRecorder = false, autoJoin = false) => {
      if (isRecorder) setIsRecorder(isRecorder);
      api
        .verifySession(lang, joiningKey || "", browserUuid)
        .then((response) => {
          const { sessionInfo, joinee, tokens, recordingInfo, chats, constraints, audioChannels, notes } = response?.data;
          updateDeepDiveState({
            callProgress: !autoJoin ? CALL_PROGRESS.GET_USER_INFO_SCREEN : CALL_PROGRESS.IN_CALL_SCREEN,
            error: null,
            joinee,
            tokens,
            channel: sessionInfo.mediaSessionKey,
            sessionInfo,
            recordingInfo,
            chats,
            constraints,
            audioChannels,
            notes,
          });
        })
        .catch((error) => {
          updateDeepDiveState({ callProgress: CALL_PROGRESS.ERROR_SCREEN, error: error?.response?.data?.message });
        });
    },
    [browserUuid, lang, updateDeepDiveState]
  );

  useEffect(() => {
    if (sessionInfo && joinee && isRecorder) {
      if (hasFetched.current) return;
      hasFetched.current = true;

      joinCall("Recorder");
    }
  }, [sessionInfo, joinee, joinCall, isRecorder]);

  useEffect(() => {
    if (sessionInfo && joinee && autoJoin) {
      if (hasFetched.current) return;
      hasFetched.current = true;

      joinCall(joinee?.joinee?.name || "");
    }
  }, [sessionInfo, joinee, joinCall, autoJoin]);

  // Quit call
  const quitCall = useCallback(
    (key: string, toRejoin = false, endReason = t("NORMAL_END_CALL_REASON"), showRejoinBtn = false) => {
      if (key === CALL_END_REASON.LEAVE) {
        // Leave
        if (!toRejoin) {
          updateDeepDiveState({
            callProgress: CALL_PROGRESS.THANK_YOU_SCREEN,
            micOn: false,
            cameraOn: false,
            screenOn: false,
            endReason,
            showRejoinBtn,
          });
        } else {
          updateDeepDiveState({
            micOn: false,
            cameraOn: false,
            screenOn: false,
          });
        }
      } else if (key === CALL_END_REASON.END_CALL) {
        // End call
        updateDeepDiveState({
          callProgress: CALL_PROGRESS.THANK_YOU_SCREEN,
          micOn: false,
          cameraOn: false,
          screenOn: false,
          endReason,
          showRejoinBtn,
        });
      }

      if (sessionInfo && joinee) {
        const sessionId = sessionInfo?.id;
        const sessionMode = sessionInfo?.sessionMode;
        const joiningKey = sessionInfo?.uniqueKey;

        const {
          joinee: { id, name, role, is_chief_moderator },
        } = joinee as Joinee;

        api
          .updateJoinee(lang, sessionId, sessionMode, channel, Number(id), name, role, Boolean(is_chief_moderator), "sessionLeft")
          .then((response) => {
            api.sendJoinStatus(lang, Number(sessionId), role, Boolean(is_chief_moderator), name, Number(id), false);
            if (toRejoin) {
              updateDeepDiveState({
                callProgress: CALL_PROGRESS.WAITING_SCREEN,
                autoJoin: toRejoin,
                joinee: undefined,
                sessionInfo: undefined,
              });
              verifySession(joiningKey || "", false, toRejoin);
            } else {
              const { joinee } = response?.data;
              updateDeepDiveState({ joinee });
            }
          })
          .catch(() => {});
      }
    },
    [t, sessionInfo, joinee, updateDeepDiveState, lang, channel, verifySession]
  );

  // Limited interaction message
  const limitInteractionDesc = useCallback(
    (key: INTERACTIONS_BETWEEN_PARTICIPANTS_TYPE) => {
      let descriptionMsg = "";
      if (key === INTERACTIONS_BETWEEN_PARTICIPANTS_TYPE.OFF) {
        if (joineeInfo?.role === USER_TYPE.MODERATOR) {
          descriptionMsg = t("SWITCH_TO_INTERACTIONS_BETWEEN_PARTICIPANTS_ENABLED_MODERATOR");
        } else if (joineeInfo?.role === USER_TYPE.PARTICIPANT) {
          descriptionMsg = t("SWITCH_TO_INTERACTIONS_BETWEEN_PARTICIPANTS_ENABLED_PARTICIPANT");
        } else {
          descriptionMsg = t("SWITCH_TO_INTERACTIONS_BETWEEN_PARTICIPANTS_ENABLED_OBSERVER");
        }
      }

      const msg =
        key === INTERACTIONS_BETWEEN_PARTICIPANTS_TYPE.OFF
          ? t("SWITCH_TO_INTERACTIONS_BETWEEN_PARTICIPANTS_ENABLED")
          : t("SWITCH_TO_INTERACTIONS_BETWEEN_PARTICIPANTS_DISABLED");

      return { msg, descriptionMsg };
    },
    [joineeInfo?.role, t]
  );

  // Handle beforeunload event
  useEffect(() => {
    const listener = (e: { preventDefault: () => void; returnValue: string }) => {
      e.preventDefault();
      e.returnValue = "";
      return "";
    };

    if (callProgress === CALL_PROGRESS.IN_CALL_SCREEN) {
      window.addEventListener("beforeunload", listener);
    } else {
      window.removeEventListener("beforeunload", listener);
      window.onbeforeunload = null;
    }

    return () => {
      window.removeEventListener("beforeunload", listener);
      window.onbeforeunload = null;
    };
  }, [callProgress, quitCall]);

  // Pass the state to the context
  const memoizedValue = useMemo<DeepDiveContextType>(
    () => ({
      ...deepDiveState,
      getMembers,
      updateDeepDiveState,
      quitCall,
      waitingParticipants,
      setWaitingParticipants,
      verifySession,
      joinCall,
      isRecorder,
      isMac,
      showFeature,
      setShowFeature,
      selectedAudioChannel,
      setSelectedAudioChannel,
      selectedAudioChannelUids,
      setSelectedAudioChannelUids,
      newChatReceived,
      setNewChatReceived,
      t,
      calling,
      setCalling,
      limitInteractionDesc,
    }),
    [
      deepDiveState,
      getMembers,
      updateDeepDiveState,
      quitCall,
      waitingParticipants,
      setWaitingParticipants,
      verifySession,
      joinCall,
      isRecorder,
      isMac,
      showFeature,
      setShowFeature,
      selectedAudioChannel,
      setSelectedAudioChannel,
      selectedAudioChannelUids,
      setSelectedAudioChannelUids,
      newChatReceived,
      setNewChatReceived,
      t,
      calling,
      setCalling,
      limitInteractionDesc,
    ]
  );

  return <DeepDiveContext.Provider value={memoizedValue}>{children}</DeepDiveContext.Provider>;
};

DeepDiveProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default DeepDiveProvider;
